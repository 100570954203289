import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Box, CssBaseline, Toolbar, Typography, AppBar } from '@mui/material';
import Sidebar from '../components/admin/Sidebar';  
import { ClipLoader } from 'react-spinners'; 

const AdminLayout = () => {
  const { isAuthenticated ,loading } = useAuth();
  if (loading) {
    return (
      <div className="spinner-container">
        <ClipLoader color="#051224" size={60}  />
      </div>
    );
  }


  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - 240px)`,
          ml: `240px`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Panel de Administración
          </Typography>
        </Toolbar>
      </AppBar>

      <Sidebar />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 3,
          width: `calc(100% - 240px)`,
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminLayout;
