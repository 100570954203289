import { useEffect, createContext, useState, useContext, useCallback } from "react";
import axiosInstance from "../hooks/axiosInstance";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const login = async (identifier, password) => {
    try {
      let loginType = identifier.includes('@') ? 'email' : 'username';
      const response = await axiosInstance.post('/user/login', { [loginType]: identifier, password });
      const data = response.data;
      setUser(data.user);
      localStorage.setItem("token", data.token);
    } catch (error) {
      console.error('Login failed:', error.response?.data?.message || 'Unknown error');
      throw error; 
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("token");
  };

  const fetchUserData = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setLoading(false);
      return;
    }
  
    try {
      const response = await axiosInstance.get('/user/auth', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('Datos del usuario:', response.data);
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error.response || error.message);
      logout();
    } finally {
      setLoading(false);
    }
  }, []);

  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    return token && user?.type === "admin";
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
