import React from 'react';
import { Box, Drawer, Toolbar, List, ListItem, ListItemText, Divider, IconButton, AppBar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; 
import CloseIcon from '@mui/icons-material/Close'; 
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import ImageIcon from '@mui/icons-material/Image'; 
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; 
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const drawerWidth = 240;

const Sidebar = () => {
  const { logout, user } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: `100%`,
          backgroundColor: '#051226'
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Panel de Administración
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 16px' }}>
          <Typography variant="h6">Menú</Typography>
          <IconButton onClick={toggleDrawer} aria-label="close drawer">
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem button component={Link} to="/admin/dashboard" onClick={toggleDrawer}>
              <HomeIcon sx={{ mr: 1 }} /> 
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button component={Link} to="/admin/courses" onClick={toggleDrawer}>
              <SportsTennisIcon sx={{ mr: 1 }} /> 
              <ListItemText primary="Gestión de Cursos" />
            </ListItem>
            <ListItem button component={Link} to="/admin/headers" onClick={toggleDrawer}>
              <ImageIcon sx={{ mr: 1 }} /> 
              <ListItemText primary="Gestión de Imágenes" />
            </ListItem>
            {user && (
              <ListItem button onClick={() => { logout(); toggleDrawer(); }} >
                <ExitToAppIcon sx={{ mr: 1 }} />
                <ListItemText primary="Logout" />
              </ListItem>
            )}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
