import { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";

const useCardsHome = () => {
  const [cardsHome, setCardsHome] = useState([]);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axiosInstance.get("/homeCard"); 
        setCardsHome(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchCards();
  }, []);

  return { cardsHome };
};

export default useCardsHome;
