import React, { useState, useEffect } from "react";
import axiosInstance from "../../hooks/axiosInstance";
import { ExpandMore } from "@mui/icons-material";
import styles from "../../assets/styles/images.module.css";

const ExpandableField = ({
  title,
  value,
  onChange,
  name,
  isTextarea,
  icon,
  isFile,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleExpand = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.expandableField}>
      <div onClick={toggleExpand} className={styles.fieldHeader}>
        {icon && <span className={`material-icons ${styles.icon}`}>{icon}</span>}
        <span>{title}</span>
        <span className={styles.arrow}><ExpandMore /></span>
      </div>
      {isOpen && (
        <div className={styles.fieldContainer}>
          {isTextarea ? (
            <textarea
              name={name}
              value={value}
              onChange={onChange}
              className={styles.inputField}
            />
          ) : isFile ? (
            <input
              type="file"
              name={name}
              onChange={onChange}
              className={styles.fileInput}
              accept="image/*"
            />
          ) : (
            <input
              type="text"
              name={name}
              value={value}
              onChange={onChange}
              className={styles.inputField}
            />
          )}
        </div>
      )}
    </div>
  );
};

const ImageModal = ({ image, onClose, onSave }) => {
  const [imageData, setImageData] = useState({
    title: "",
    imageUrl: null,
  });

  useEffect(() => {
    if (image) {
      setImageData({
        title: image.title,
        imageUrl: image.imageUrl || null,
      });
    }
  }, [image]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setImageData({
      ...imageData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setImageData({
      ...imageData,
      imageUrl: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (imageData.title) {
      formData.append("title", imageData.title);
    }

    if (imageData.imageUrl) {
      formData.append("image", imageData.imageUrl);
    }

    try {
      if (image) {
        await axiosInstance.put(`/image/${image._id}`, formData);
        alert("Imagen actualizada exitosamente");
      }

      onSave();
      onClose();
    } catch (error) {
      console.error("Error saving image:", error);
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h2>{"Editar Imagen"}</h2>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          {imageData.title && (
            <ExpandableField
              title="Título"
              value={imageData.title}
              onChange={handleInputChange}
              name="title"
            />
          )}
          <ExpandableField
            title="Imagen"
            name="image"
            onChange={handleFileChange}
            isFile={true}
          />
          <div className={styles.modal_buttons_container}>
            {image && (
              <button type="submit" className={styles.button_modal_1}>
                Actualizar Imagen
              </button>
            )}
            <button type="button" onClick={onClose} className={styles.button_modal_2}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ImageModal;
