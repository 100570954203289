import React from "react";
import "../assets/styles/Facilities.css";
import SliderComponent from "../components/SliderComponent";
import FacilitiesComponent from "../components/FacilitiesComponent";
import useHeaders from "../hooks/useHeaders";
import { ClipLoader } from 'react-spinners'; 
import { Helmet } from "react-helmet";

const Facilities = () => {
  const { headersData } = useHeaders();

  if (!headersData || headersData.length < 4) {
    return (
      <div className="spinner-container">
        <ClipLoader color="#051224" size={60}  />
      </div>
    );
  }

  return (
    <div className="facilities_wrapper">
      <Helmet>
        <title>Instalaciones - Academia Muñoz</title>
        <meta
          name="description"
          content="Explora las instalaciones de primera clase de la Academia Muñoz en Villavicencio. Ofrecemos un gimnasio moderno, canchas de tenis y mucho más para tu comodidad."
        />
      </Helmet>
      <FacilitiesComponent
        title={headersData[4].title}
        img={headersData[4].imageUrl}
        alt={"initial"}
      />
      <SliderComponent />
      {/* <MapComponent /> */}
    </div>
  );
};

export default Facilities;
