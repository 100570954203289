import React from "react";

const FacilitiesComponent = ({img ,alt, title }) => {
  return (
    <div className="facilities_container">
      <img src={img} alt={alt} />
      <div className="facilities_text_wrapper">
        <div className="facilities_text_container">
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default FacilitiesComponent;
