import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Courses from "./pages/Courses";
import Footer from "./components/Footer";
import Facilities from "./pages/Facilities";
import ScrollToTop from "./components/ScrollToTop";
import Ranking from "./pages/Ranking";
import CourseManager from "./pages/admin/CourseManager";
import AdminLayout from "./layouts/AdminLayout";
import Login from "./pages/Login";
import { AuthProvider } from "./context/AuthContext";
import ImageManager from "./pages/admin/ImageManager";
import Dashboard from "./pages/admin/Dashboard";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Home />
                <Footer />
              </>
            }
          />
          <Route
            path="/courses"
            element={
              <>
                <Navbar />
                <Courses />
                <Footer />
              </>
            }
          />
          <Route
            path="/facilities"
            element={
              <>
                <Navbar />
                <Facilities />
                <Footer />
              </>
            }
          />
          <Route
            path="/ranking"
            element={
              <>
                <Navbar />
                <Ranking />
                <Footer />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Navbar />
                <Login />
                <Footer />
              </>
            }
          />
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="courses" element={<CourseManager />} />
            <Route path="headers" element={<ImageManager/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
