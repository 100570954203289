import React from 'react';
import { Box, Grid, Toolbar, Typography, Card, CardContent, CardMedia } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ImageIcon from '@mui/icons-material/Image';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Box
      component="main"
      sx={{ display: 'flex' }}
    >
      <Toolbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom align="center">
            ¡Bienvenido/a al Panel de Administración!
          </Typography>
          <Typography variant="subtitle1" align="center">
            Administra fácilmente tus cursos, imágenes y más desde este panel.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            elevation={3}
            sx={{ textAlign: 'center', cursor: 'pointer', height: '200px' }} 
            onClick={() => handleNavigation('/admin/courses')}
          >
            <CardMedia>
              <DashboardIcon sx={{ fontSize: 50, color: '#3f51b5' }} /> 
            </CardMedia>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
              <Typography variant="h6" sx={{ mt: 1 }}> 
                Gestión de Cursos
              </Typography>
              <Typography variant="body2" sx={{ mt: 0.5 }}>
                Administra todos los cursos disponibles y actualiza el contenido fácilmente.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            elevation={3}
            sx={{ textAlign: 'center', cursor: 'pointer', height: '200px' }} 
            onClick={() => handleNavigation('/admin/headers')} 
          >
            <CardMedia>
              <ImageIcon sx={{ fontSize: 50, color: '#3f51b5' }} />
            </CardMedia>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
              <Typography variant="h6" sx={{ mt: 1 }}> 
                Gestor de Imágenes
              </Typography>
              <Typography variant="body2" sx={{ mt: 0.5 }}>
                Administra las imágenes de tu plataforma de manera sencilla.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
