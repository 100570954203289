import { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";

const useRankings = () => {
  const [rankings, setRankings] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const response = await axiosInstance.get("/ranking");
        setRankings(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchRankings();
  }, []);

  return { rankings, loading, error };
};

export default useRankings;
