import { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";

const useHeaders = () => {
  const [headersData, setHeadersData] = useState([]);

  useEffect(() => {
    const fetchHeaders = async () => {
      try {
        const response = await axiosInstance.get("/image");
        setHeadersData(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchHeaders();
  }, []);

  return { headersData };
};

export default useHeaders;
