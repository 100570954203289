import React from 'react';
import Slider from 'react-slick';
import { ClipLoader } from 'react-spinners'; 
import useHeaders from '../hooks/useHeaders';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/styles/Carousel.css';

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-prev`}
      onClick={onClick}
    />
  );
};

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-next`}
      onClick={onClick}
    />
  );
};

const Carousel = () => {
  const { headersData } = useHeaders();

  if (!headersData || headersData.length < 3) {
    return (
      <div className="spinner-container">
        <ClipLoader color="#051224" size={60}  />
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings} className="carousel">
      <div className="carousel-slide">
        <video
          width="100%"
          height="100%"
          controls
          autoPlay
          muted
        >
          <source src={headersData[0].imageUrl} type="video/mp4" />
          Tu navegador no soporta la etiqueta de video.
        </video>
      </div>
      <div className="carousel-slide">
        <img src={headersData[1].imageUrl} alt="Slide 1" />
      </div>
      <div className="carousel-slide">
        <img src={headersData[2].imageUrl} alt="Slide 2" />
      </div>
    </Slider>
  );
};

export default Carousel;
