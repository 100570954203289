import React from "react";

const MapComponent = () => {
  return (
    <div>
      <div className="block_find">
        <div className="text">Encuéntranos</div>
      </div>
      <div className="wrapper">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3979.2447835136923!2d-73.6287497!3d4.172219699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3e2d549bf9e94b%3A0x61343b3f436dd3b6!2sCentro%20Deportivo%20de%20Tenis%20Mu%C3%B1oz!5e0!3m2!1ses-419!2sco!4v1722970920031!5m2!1ses-419!2sco"
          width="600"
          height="450"
          style={{ border: 0 }}
          title="Mapa del Centro Deportivo de Tenis Muñoz"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default MapComponent;
