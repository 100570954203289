import React, { useState, useEffect } from "react";
import axiosInstance from "../../hooks/axiosInstance"; 
import { ExpandMore } from "@mui/icons-material";
import styles from "../../assets/styles/courseModal.module.css";

const ExpandableField = ({
  title,
  value,
  onChange,
  name,
  isTextarea,
  icon,
  isFile,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleExpand = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.expandableField}>
      <div onClick={toggleExpand} className={styles.fieldHeader}>
        {icon && (
          <span className={`material-icons ${styles.icon}`}>{icon}</span>
        )}
        <span>{title}</span>
        <span className={styles.arrow}>
          <ExpandMore />
        </span>
      </div>
      {isOpen && (
        <div className={styles.fieldContainer}>
          {isTextarea ? (
            <textarea
              name={name}
              value={value}
              onChange={onChange}
              className={styles.inputField}
            />
          ) : isFile ? (
            <input
              type="file"
              name={name}
              onChange={onChange}
              className={styles.fileInput}
            />
          ) : (
            <input
              type="text"
              name={name}
              value={value}
              onChange={onChange}
              className={styles.inputField}
            />
          )}
        </div>
      )}
    </div>
  );
};

const CourseModal = ({ course, onClose, onSave }) => {
  const [courseData, setCourseData] = useState({
    title: "",
    subtitle: "",
    description: "",
    imageSrc: null,
    ref: "",
    buttonText: "",
    buttonLink: "",
    details: [],
  });

  useEffect(() => {
    if (course) {
      setCourseData({
        title: course.title,
        subtitle: course.subtitle || "",
        description: course.description,
        imageSrc: course.imageSrc || null,
        ref: course.ref || "",
        buttonText: course.buttonText || "",
        buttonLink: course.buttonLink || "",
        details: course.details || [],
      });
    } else {
      setCourseData((prevData) => ({
        ...prevData,
        details: [
          { label: "IMPARTIDO POR", icon: "SportsTennis" },
          { label: "DÍAS DE ENTRENAMIENTO", icon: "CalendarMonth" },
          { label: "HORARIO DE ENTRENAMIENTO", icon: "CalendarMonth" },
          { label: "TIEMPO DE ENTRENAMIENTO", icon: "AccessTime" },
          { label: "REQUISITOS", icon: "AssignmentIndIcon" },
        ],
      }));
    }
  }, [course]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseData({
      ...courseData,
      [name]: value,
    });
  };

  const handleDetailChange = (index, field, value) => {
    const updatedDetails = [...courseData.details];
    updatedDetails[index][field] = value;
    setCourseData({ ...courseData, details: updatedDetails });
  };

  const handleFileChange = (e) => {
    setCourseData({
      ...courseData,
      imageSrc: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("title", courseData.title);
    formData.append("subtitle", courseData.subtitle);
    formData.append("description", courseData.description);

    if (courseData.imageSrc) {
      formData.append("imageSrc", courseData.imageSrc);
    }

    formData.append("ref", courseData.ref);
    formData.append("buttonText", courseData.buttonText);
    formData.append("buttonLink", courseData.buttonLink);

    const detailsWithoutId =
      courseData.details && Array.isArray(courseData.details)
        ? courseData.details.map(({ _id, ...rest }) => rest)
        : [];

    formData.append("details", JSON.stringify(detailsWithoutId));

    try {
      if (course) {
        await axiosInstance.put(`/course/${course._id}`, formData);
        alert("Curso actualizado exitosamente");
      } else {
        await axiosInstance.post("/course", formData);
        alert("Curso creado exitosamente");
      }

      onSave();
      onClose();
    } catch (error) {
      console.error("Error saving course:", error);
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h2>{course ? "Editar Curso" : "Crear Nuevo Curso"}</h2>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <ExpandableField
            title="Título"
            value={courseData.title}
            onChange={handleInputChange}
            name="title"
          />
          <ExpandableField
            title="Subtítulo"
            value={courseData.subtitle}
            onChange={handleInputChange}
            name="subtitle"
          />
          <ExpandableField
            title="Descripción"
            value={courseData.description}
            onChange={handleInputChange}
            name="description"
            isTextarea={true}
          />
          <div className={styles.fieldContainer}>
            <h3>Detalles del Curso</h3>
            {courseData.details.map((detail, index) => (
              <div key={index}>
                <ExpandableField
                  title={detail.label}
                  value={detail.content}
                  onChange={(e) =>
                    handleDetailChange(index, "content", e.target.value)
                  }
                  name={`detail-${index}`}
                />
              </div>
            ))}
          </div>
          <ExpandableField
            title="Imagen"
            name="imageSrc"
            onChange={handleFileChange}
            isFile={true}
          />
          <ExpandableField
            title="Referencia"
            value={courseData.ref}
            onChange={handleInputChange}
            name="ref"
          />
          <ExpandableField
            title="Texto del Botón"
            value={courseData.buttonText}
            onChange={handleInputChange}
            name="buttonText"
          />
          <ExpandableField
            title="Enlace del Botón"
            value={courseData.buttonLink}
            onChange={handleInputChange}
            name="buttonLink"
          />
          <div className={styles.modal_buttons_container}>
            <button type="submit" className={styles.button_modal_1}>
              {course ? "Actualizar Curso" : "Crear Curso"}
            </button>
            <button type="button" onClick={onClose} className={styles.button_modal_2}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CourseModal;
