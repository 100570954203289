import React, { useState, useEffect } from "react";
import axiosInstance from "../../hooks/axiosInstance"; 
import ImageModal from "../../components/admin/ImageModal"; 
import styles from "../../assets/styles/images.module.css"; 

const ImageManager = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axiosInstance.get("/image"); // Usa la instancia
      const filterImages = response.data.map((image, index) => {
        return {
          ...image,
          title: image.title || `imagen_inicio${index}`,
        };
      });
      setImages(filterImages);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleEditClick = (image) => {
    setSelectedImage(image);
    setShowEditModal(true);
  };

  const handleModalClose = () => {
    setShowEditModal(false);
  };

  const handleSave = () => {
    setShowEditModal(false);
    fetchImages();
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Administrar Imágenes</h1>
      <table className={styles.imageTable}>
        <thead>
          <tr>
            <th className={styles.tableHeader}>Título</th>
            <th className={styles.tableHeader}>Imagen URL</th>
            <th className={styles.tableHeader}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {images.map((image) => (
            <tr key={image._id}>
              <td className={styles.tableData}>{image.title}</td>
              <td className={styles.tableData}>{image.imageUrl}</td>
              <td className={styles.tableData}>
                <button className={styles.editButton} onClick={() => handleEditClick(image)}>
                  Editar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showEditModal && selectedImage && (
        <ImageModal
          image={selectedImage}
          onClose={handleModalClose}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default ImageManager;
