import React from 'react';
import { Link } from 'react-router-dom';
import "../assets/styles/Card.css";

const CardComponent = ({ image, title, description, alt, link }) => {
  return (
    <Link to={link} className="card_link">
      <div className="card_container">
        <img className="card_img" src={image} alt={alt} />
        <div className="card_body">
          <h1 className="title">{title}</h1>
          <p>{description}</p>
        </div>
      </div>
    </Link>
  );
}

export default CardComponent;
