import React, { useEffect } from "react";
import "../assets/styles/Courses.css";
import CourseCard from "../components/CourseCard";
import FacilitiesComponent from "../components/FacilitiesComponent";
import useCourseCard from "../hooks/useCourseCard";
import useHeaders from "../hooks/useHeaders";
import { ClipLoader } from 'react-spinners'; 
import { Helmet } from 'react-helmet';

const Courses = () => {
  const {courseCard} = useCourseCard();
  const {headersData} = useHeaders();
  
  useEffect(() => {
    const scrollToElement = (id) => {
      const element = document.getElementById(id);
      if (element) {
        const yOffset = -120;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    const hash = window.location.hash.replace("#", "");
    if (hash) {
      scrollToElement(hash);
    }
  }, []);

  if (!headersData || headersData.length < 4) {
    return (
      <div className="spinner-container">
        <ClipLoader color="#051224" size={60}  />
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Cursos de Tenis - Academia Muñoz</title>
        <meta
          name="description"
          content="Descubre los mejores cursos de tenis en Villavicencio ofrecidos por la Academia Muñoz. Aprende y mejora tus habilidades con nuestros entrenadores profesionales."
        />
      </Helmet>
      <FacilitiesComponent
        img={headersData[3].imageUrl}
        alt={"trn"}
        title={headersData[3].title}
      />
      <main className="courses_template">
        <ul className="courses__list">
          {courseCard.map((course) => (
            <CourseCard
              key={course._id}
              id={course.ref}
              imageSrc={course.imageSrc}
              title={course.title}
              subtitle={course.subtitle}
              description={course.description}
              details={course.details}
              buttonText={course.buttonText}
              buttonLink={course.buttonLink}
            />
          ))}
        </ul>
      </main>
    </div>
  );
};

export default Courses;
