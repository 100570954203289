import React, { useState, useEffect } from 'react';
import axiosInstance from '../../hooks/axiosInstance';
import CourseModal from '../../components/admin/CourseModal';
import styles from '../../assets/styles/courses.module.css'; 

const CourseManager = () => {
  const [courses, setCourses] = useState([]); 
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false); 

  useEffect(() => {
    fetchCourses(); 
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await axiosInstance.get('/course'); // Usa la instancia
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const handleCreateClick = () => {
    setSelectedCourse(null);
    setShowCreateModal(true);
  };

  const handleEditClick = (course) => {
    setSelectedCourse(course); 
    setShowEditModal(true); 
  };

  const handleModalClose = () => {
    setShowCreateModal(false); 
    setShowEditModal(false);
  };

  const handleSave = async () => {
    setShowCreateModal(false);
    setShowEditModal(false);
    fetchCourses(); 
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Administrar Cursos</h1>
      <button className={styles.createButton} onClick={handleCreateClick}>Crear Nuevo Curso</button>
      <table className={styles.courseTable}>
        <thead>
          <tr>
            <th className={styles.tableHeader}>Título</th>
            <th className={styles.tableHeader}>Descripción</th>
            <th className={styles.tableHeader}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course) => (
            <tr key={course._id}>
              <td className={styles.tableData}>{course.title}</td>
              <td className={styles.tableData}>{course.description}</td>
              <td className={styles.tableData}>
                <button className={styles.editButton} onClick={() => handleEditClick(course)}>Editar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showCreateModal && (
        <CourseModal
          course={null} 
          onClose={handleModalClose}
          onSave={handleSave}
        />
      )}

      {showEditModal && selectedCourse && (
        <CourseModal
          course={selectedCourse} 
          onClose={handleModalClose}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default CourseManager;
