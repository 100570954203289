import { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Container, TextField, Button, Typography, Box } from "@mui/material";

const Login = () => {
  const [loginData, setLoginData] = useState({
    identifier: "",
    password: "",
  });

  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { identifier, password } = loginData;
      await login(identifier, password);
      navigate("/admin/dashboard");
    } catch (error) {
      alert(
        "Login failed: " + (error.response?.data?.message || "Unknown error")
      );
    }
  };

  const handleChange = async (e) => {
    const { id, value } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  return (
    <Container maxWidth="xs" sx={{ minHeight: "41vh" }}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="identifier"
          label="Username or Email"
          name="identifier"
          autoComplete="identifier"
          autoFocus
          value={loginData.identifier}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={loginData.password}
          onChange={(e) => handleChange(e)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            backgroundColor: "#051224",
            "&:hover": {
              backgroundColor: "#03111b",
            },
          }}
        >
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
