import React, { useEffect, useState } from "react";
import "../assets/styles/Home.css";
import CardComponent from "../components/CardComponent";
import Carousel from "../components/Carousel";
import MapComponent from "../components/MapComponent";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import useCardsHome from "../hooks/useHomeCards";
import utrImg from "../assets/utr.jpeg";
const Home = () => {
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const { cardsHome } = useCardsHome();

  const updateButtonStates = () => {
    const container = document.querySelector(".mn_layout-container");
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      setIsPrevDisabled(scrollLeft === 0);
      setIsNextDisabled(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  useEffect(() => {
    const container = document.querySelector(".mn_layout-container");
    updateButtonStates();

    container.addEventListener("scroll", updateButtonStates);
    window.addEventListener("resize", updateButtonStates);

    return () => {
      container.removeEventListener("scroll", updateButtonStates);
      window.removeEventListener("resize", updateButtonStates);
    };
  }, []);

  const scrollLeft = () => {
    const container = document.querySelector(".mn_layout-container");
    if (container) {
      container.scrollBy({
        left: -container.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    const container = document.querySelector(".mn_layout-container");
    if (container) {
      container.scrollBy({
        left: container.clientWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title>Inicio</title>
        <meta
          name="description"
          content="Bienvenido a la mejor academia de tennis en Villavicencio Vanguardia."
        ></meta>
      </Helmet>
      <Carousel />
      <div className="block">
        <div className="text">Academia de Tennis Muñoz</div>
      </div>
      <div className="wrapper">
        <p className="about_title_mobile">Cursos de calidad en Vanguardia</p>
        <div className="about_container">
          <div className="about_image_container">
            <iframe
              className="about_video"
              src="https://www.youtube.com/embed/comzuGbwmwM?si=mZtSvLziKeNrvyxO"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="about_section_text">
            <h2>Hermosas instalaciones en Vanguardia</h2>
            <p>
              Formar parte de la Academia Muñoz durante un curso de formación
              implica integrarse a una comunidad apasionada. La Academia se
              encuentra en la cuidad de Villavicencio vía Vanguardia , la cual
              cuenta con amplias instalaciones y un clima competitivo y
              familiar.
            </p>
            <div className="button_text">
              <Link to={"facilities"} className="btn btn--primary">
                Conoce nuestras instalaciones{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="space-large"></div>
      <div className="courses_section">
        <h2>Cursos</h2>
        <div className="mn_layout">
          <div
            className={`swiper-button-prev ${
              isPrevDisabled ? "swiper-button-disabled" : ""
            }`}
            role="button"
            aria-label="Previous slide"
            onClick={scrollLeft}
            style={{ cursor: isPrevDisabled ? "not-allowed" : "pointer" }}
          >
            {/* SVG code for previous button */}
          </div>
          <div className="mn_layout-container">
            {cardsHome.map((card) => (
              <CardComponent
                key={card._id}
                className="card"
                image={card.image}
                title={card.title}
                description={card.description}
                link={card.link}
              />
            ))}
          </div>
          <div
            id="nex"
            className={`swiper-button-next ${
              isNextDisabled ? "swiper-button-disabled" : ""
            }`}
            role="button"
            aria-label="Next slide"
            onClick={scrollRight}
            style={{ cursor: isNextDisabled ? "not-allowed" : "pointer" }}
          >
            {/* SVG code for next button */}
          </div>
        </div>
      </div>
      <div className="tournaments_section">
        <div className="block_find">
          <div className="text">UTR del mes</div>
        </div>
        <div className="tournament_image_container">
          <img
            src={utrImg}
            alt="Imagen de Torneros"
            className="tournament_image"
          />
        </div>
      </div>
      <MapComponent />
    </div>
  );
};

export default Home;
