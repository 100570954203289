import { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { AccessTime, CalendarMonth, SportsTennis, Watch } from '@mui/icons-material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

const useCourseCard = () => {
  const [courseCard, setCourseCard] = useState([]);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const response = await axiosInstance.get("/course");
        const updatedCourses = response.data.map(course => {
          return {
            ...course,
            details: course.details.map(detail => {
              switch (detail.icon) {
                case "SportsTennis":
                  return { ...detail, icon: <SportsTennis /> };
                case "CalendarMonth":
                  return { ...detail, icon: <CalendarMonth /> };
                case "Watch":
                  return { ...detail, icon: <Watch /> };
                case "AccessTime":
                  return { ...detail, icon: <AccessTime /> };
                case "AssignmentIndIcon":
                  return { ...detail, icon: <AssignmentIndIcon /> };
                default:
                  return detail;
              }
            })
          };
        });

        setCourseCard(updatedCourses);
      } catch (err) {
        console.error(err);
      }
    };

    fetchRankings();
  }, []);

  return { courseCard };
};

export default useCourseCard;
